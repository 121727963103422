import axios from "axios";
const url='https://attend.fjutxiake.com/api'
// const url="http://localhost:8080"
const common={
    url:url,
    method: "POST",
    async: true,
    crossDomain: true,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;  charset=UTF-8',
    },
    // 使得每次上传的session保持一致
    xhrFields: {
        withCredentials: true
    },
    data: {},
}
const request = {
    getPost: async(options = {}) => {
        try{
            const response=await axios({
                url: options.url,
                headers: options.headers || common.headers,
                method: options.method || common.method,
                data: options.data || common.data,
                responseType:options.responseType || ""
            })
            if (response.status === 200) {
                return (response.data);
            } else {
                throw(response.data);
            }
        } catch(error){
            throw err;
        }
        
    },
    getPort: async(options = {}) => {
        try{
            const response=await axios({
                url: options.url,
                headers: options.headers || common.headers,
                method: options.method || common.method,
                data: options.data || common.data,
                responseType:options.responseType || ""
            })
            if (response.status === 200) {
                return (response);
            } else {
                throw(response.data);
            }
        } catch(error){
            throw err;
        }
        
    },
};
export default request