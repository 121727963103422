import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import request from '@/utils/request';
// 全局注册request
Vue.prototype.$request = request;
Vue.use(VueAxios, axios);
Vue.use(ElementUI);
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {  // 需要权限
      //判断当前是否拥有权限
      if (sessionStorage.getItem('login')) {
          next();
      } else {  // 无权，跳转登录
        next('/');
      }
  } else {  // 不需要权限，直接访问
      next();
  }
})
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
// 设置浏览器标题
Vue.directive('title', {
  inserted: function () {
    document.title ='霞客会议签到管理系统'
  }
});
