import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// 子路由的通用 meta 配置
const requireAuthMeta = { requireAuth: true };
const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../view/login.vue'),
    },
    {
        path: '/drawLots',
        name: 'drawLots',
        meta: requireAuthMeta,
        component: () => import('../view/drawLots.vue'),
    },
    {
        path: '/Main',
        name: 'Main',
        meta: requireAuthMeta,
        component: () => import('../view/Main.vue'),
        children: [
            {
                path: '/CreateMeeting',
                name: 'CreateMeeting',
                meta: requireAuthMeta,
                component: () => import('../view/CreateMeeting.vue')
            },
            {
                path: '/CreateArrange',
                name: 'CreateArrange',
                meta: requireAuthMeta,
                component: () => import('../view/CreateArrange.vue'),
            },
            {
                path: '/AlterArrange',
                name: 'AlterArrange',
                meta: requireAuthMeta,
                component: () => import('../view/AlterArrange.vue'),
            },
            {
                path: '/InquireArrange',
                name: 'InquireArrange',
                meta: requireAuthMeta,
                component: () => import('../view/InquireArrange.vue'),
            },
            {
                path: '/ViewMeetings',
                name: 'ViewMeetings',
                meta: requireAuthMeta,
                component: () => import('../view/ViewMeetings.vue'),

            },
            {
                path: '/ViewStartedMeetings',
                name: 'ViewStartedMeetings',
                meta: requireAuthMeta,
                component: () => import('../view/ViewStartedMeetings.vue')
            },
            {
                path: '/information',
                name: 'information',
                meta: requireAuthMeta,
                component: () => import('../view/Information.vue'),
            },
            {
                path: '/changeMeeting',
                name: 'changeMeeting',
                meta: requireAuthMeta,
                component: () => import('../view/changeMeeting.vue')
            },
            {
                path: '/addAdmin',
                name: 'addAdmin',
                meta: requireAuthMeta,
                component: () => import('../view/addAdmin.vue')
            },
            {
                path: '/person',
                name: 'person',
                meta: requireAuthMeta,
                component: () => import('../view/person.vue')
            },
            {
                path: '/manager',
                name: 'manager',
                meta: requireAuthMeta,
                component: () => import('../view/manager.vue'),
            }
        ],
    },

]
const router = new VueRouter({
    mode: 'hash',
    routes
})
// const router = new VueRouter({
//     // 配置路由和组件之间的应用关系
//     routes,
//     mode: 'history',
//     linkActiveClass: 'active'
// })
export default router